import React from 'react'
import { graphql } from 'gatsby'
import { Helmet, useTranslation } from 'gatsby-plugin-react-i18next'

import Layout from '../../components/layout'
import Prefooter from '../../components/prefooter'
import SectionHeader from '../../components/section-header'
import PageHeader from '../../components/page-header'
import QuestionAccordion from '../../components/question-accordion'
import PageIntro from '../../components/page-intro'
import PageSteps from '../../components/page-steps'
import PageClient from '../../components/page-client'
import ColorSwitch from '../../components/waypoint-color-switch'
import Drift from '../../components/waypoint-drift'

import EducationIcon from '../../components/Icons/Education'

function EducationWorkshopsPage({ data }) {
  const [t, { language }] = useTranslation(['translations', 'seo'])
  const clients = data.allPrismicClients.edges.map(item => item.node)
  const slideQuestions = [
    {
      question: `We want to move away from our siloed departments, but need clear steps for change.`,
      answer: `Through facilitated workshops, we’ll work with your teams to map out internal workflows, dependencies, and  strengths. We’ll provide communication plans and clear steps to take.`,
    },
    {
      question: `We want our organization to adapt to the future and need guidance building a new vision.`,
      answer: `We’ll interview your leaders, key stakeholders, and users to help build a birds-eye view of your vision at each layer of the business. We’ll synthesize key findings and collaboratively create your future roadmap and clear vision statements.`,
    },
    {
      question: `We want to bring new methodologies into our organization, but don’t know where to start.`,
      answer: `Every service we provide is also something we teach. We have skilled facilitators that run design strategy, agile  development, communication, and design thinking sessions to introduce concepts and skills to your teams.`,
    },
    {
      question: `We are interested in creating a digital product but need help creating innovative concepts.`,
      answer: `We specialize in design sprints. Over several days, we’ll move from problem definition, to needs assessment, to solution proposition—then into design and validation. We’ll emerge with an executable concept that you can hit the ground running with.`,
    },
  ]
  return (
    <React.Fragment>
      <Helmet
        title={t('EducationWorkshopsPage.title', { ns: 'seo' })}
        meta={[
          {
            name: 'description',
            content: t('EducationWorkshopsPage.description', { ns: 'seo' }),
          },
          {
            name: 'keywords',
            content: t('EducationWorkshopsPage.keywords', { ns: 'seo' }),
          },
          {
            property: 'og:title',
            content: t('EducationWorkshopsPage.ogTitle', { ns: 'seo' }),
          },
          {
            property: 'og:description',
            content: t('EducationWorkshopsPage.ogDescription', { ns: 'seo' }),
          },
          {
            property: 'og:image',
            content: 'https://versett.com/images/social-education.jpg',
          },
          {
            property: 'og:url',
            content: 'https://versett.com/services/education-workshops',
          },
          { name: 'twitter:card', content: 'summary_large_image' },
          { property: 'og:site_name', content: 'Versett' },
          { name: 'twitter:image:alt', content: 'Education & Workshops' },
        ]}
      >
        <html lang={language} className="s-page s-page--education" />
      </Helmet>
      <Layout>
        <section className="s-container s-page__hero s-page__hero--short">
          <PageHeader
            lede={t('EducationService.heading')}
            subheading={t('EducationService.subheading')}
          />
        </section>
        <section className="s-container">
          <section className="s-page__icon">
            <EducationIcon />
          </section>
        </section>
        <ColorSwitch />
        <Drift />
        <div>
          <PageIntro>
            <h4>{t('EducationService.description')}</h4>
          </PageIntro>
          <QuestionAccordion
            title={t('EducationService.questionsHeading')}
            items={t('EducationService.faqs', { returnObjects: true })}
          />
          <section className="s-section s-container grid grid--2col-1-1">
            <div>
              <img src="/images/education-02.jpg" alt="" />
            </div>
            <div>
              <img src="/images/education-01.jpg" alt="" />
            </div>
          </section>
          <PageSteps
            callToAction={t('EducationService.projectWorkLink')}
            heading={t('EducationService.projectWorkHeading')}
          >
            <ol>
              {t('EducationService.projectWorkTasks', {
                returnObjects: true,
              }).map(task => (
                <li key={task.name}>{task.name}</li>
              ))}
            </ol>
          </PageSteps>
          <section className="s-container s-section s-page__clients">
            <SectionHeader
              lede={t('EducationService.projectExamplesHeading')}
              classes="t-section-heading--less-bottom"
            />
            {clients.map(client => (
              <PageClient key={client.id} client={client} page="Education" />
            ))}
          </section>
        </div>
        <Prefooter />
      </Layout>
    </React.Fragment>
  )
}

export const query = graphql`
  query educationQueries($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allPrismicClients(
      sort: { fields: [data___weight], order: ASC }
      filter: {
        data: {
          page_specific: { elemMatch: { page_type: { eq: "Education" } } }
        }
        lang: { eq: $language }
      }
    ) {
      edges {
        node {
          id
          data {
            name
            page_specific {
              page_type
              content {
                document {
                  ... on PrismicClientServiceExamples {
                    data {
                      description
                      project_name
                      images {
                        image {
                          url
                          thumbnails {
                            small_1x {
                              url
                            }
                            small_2x {
                              url
                            }
                            medium_1x {
                              url
                            }
                            medium_2x {
                              url
                            }
                            large_1x {
                              url
                            }
                            large_2x {
                              url
                            }
                          }
                        }
                      }
                      video {
                        url
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default EducationWorkshopsPage
