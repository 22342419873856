import React from 'react'

export default () => {
  return (
    <svg
      viewBox="0 0 100 100"
      className="c-icon c-icon--education"
      preserveAspectRatio="xMinYMid meet"
    >
      <circle cx="70" cy="30" r="29" />
      <circle cx="30" cy="30" r="29" />
      <circle cx="30" cy="70" r="29" />
      <circle cx="70" cy="70" r="29" />
    </svg>
  )
}
